<template>
  <div class="container">
    <div @click="goback" style="font-size:14px;margin-bottom:10px;cursor: pointer;width:50px">
      <i class="el-icon-back"></i>
      返回
    </div>
    <div class="search" style="display:flex">
      <el-form ref="searchform" style="flex:1" :inline="true" class="demo-form-inline">
      </el-form>
      <div>
        <el-button type="primary" size="small" @click="AddsenderUser">新建</el-button>
      </div>
    </div>

    <el-table border v-bind:data="list">
      <el-table-column label="使用者账号">
        <template v-slot="scope">
          <span>{{ scope.row.adminer_account }}</span>
        </template>
      </el-table-column>
      <el-table-column label="使用者姓名">
        <template v-slot="scope">
          <span>{{ scope.row.adminer_name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template v-slot="scope">
          <el-button type="danger" size="mini" @click="Delete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="handleCurrentChange"
      v-model:currentPage="search.page"
      :page-size="search.limit"
      layout="total, prev, pager, next"
      :total="total">
    </el-pagination>

    <el-dialog title="新增使用者" v-model="dialogFormVisible" width="600px">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="使用者" prop="adminer_id" label-width="130px">
          <el-select v-model="form.adminer_id">
            <el-option
              v-for="item in adminList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="Validate(Addconfirm)">确认</el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: '',
  props: {},
  setup () {
    return {
    }
  },
  mixins: [],
  components: {},
  data () {
    return {
      search: {
        page: 1,
        limit: 10,
        mailbox_sender_code: ''
      },
      list: [],
      total: 0,
      dialogFormVisible: false,
      form: {
        adminer_id: '',
        mailbox_sender_code: ''
      },
      rules: {
        adminer_id: [{ required: true, message: '请输入使用人账号', trigger: 'blur' }],
      },
      adminList: []
    }
  },
  computed: {},
  watch: {},
  methods: {
    GetList () {
      this.api.EmailSenderUserList(this.search).then(res => {
        if (res.data.code == 200) {
          this.list = res.data.data.data
          this.total = res.data.data.page_info.total
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    handleCurrentChange(val) {
      this.search.page = val
      this.GetList()
    },
    Validate: function (call) {
      this.$refs['form'].validate(res => {
        if (res && call) {
          call()
        }
      })
    },
    GetadminList () {
      this.api.Userlist({
        page: 1,
        limit: 100,
      }).then(res => {
        if (res.data.code == 200) {
          this.adminList = res.data.data.data
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    Addconfirm() {
      this.api.EmailSenderUserUpdate({
        adminer_id: this.form.adminer_id,
        mailbox_sender_code: this.search.mailbox_sender_code
      }).then(res => {
        if (res.data.code == 200) {
          this.dialogFormVisible = false
          this.GetList()
          this.$message.success('新增成功')
          return
        }
        this.$message.error('新增失败' + res.data.msg)
      })
    },
    AddsenderUser () {
      this.dialogFormVisible = true
      this.GetadminList()
    },
    Delete(item) {
      this.$confirm('是否删除该发件人使用者?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 删除请求
        this.api.EmailSenderUserDelete({
          code: item.code,
        }).then(res => {
           if (res.data.code == 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.GetList()
            return
          }
          this.$message.error('删除失败' + res.data.msg)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消删除'
        });
      })
    },
    goback() {
      this.$router.push('/message/sender')
    }
  },
  filters: {},
  mounted () {
    this.search.mailbox_sender_code = this.$route.params.code
    this.GetList()
  },
  created () { }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
</style>
